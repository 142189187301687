import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/Layout/Layout'
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'

// markup
const Documentation = () => {
  useLayoutEffect(() => {}, [])

  return (
    <Layout>
      <title>Consupedia Webhook Documentation</title>
      {/* <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Webhook</h1>
          </div>
        </div>
      </div> */}
      <section className="alignfull sidebar-page">
        <div className="nav">
          <>
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId="/documentation/#about"
              onSelect={({ itemId }) => {
                // maybe push to the route
                navigate(itemId)
              }}
              items={[
                {
                  title: 'Documentation',
                  itemId: '/documentation',
                  subNav: [
                    {
                      title: 'Introduction',
                      itemId: '/documentation/#introduction'
                    },
                    {
                      title: 'Fields',
                      itemId: '/documentation/#fields'
                    },
                    {
                      title: 'RESTful API',
                      itemId: '/documentation/#restful'
                    },
                    {
                      title: 'Webhook',
                      itemId: '/documentation/#webhook'
                    }
                  ]
                }
              ]}
            />
          </>
        </div>
        <div className="section-container">
          <div id="introduction">
            <h2>Introduction</h2>
            <p>
              Consupedia is a platform that provides comprehensive information
              on the environmental, social, and ethical aspects of food
              products. Our goal is to help developers build applications and
              services that empower consumers to make informed, sustainable
              choices and promote responsible consumption.
            </p>

            <p>
              Our developer services offer access to a wealth of data, including
              product descriptions, images, categories, and sustainability
              indicators such as carbon footprint, water footprint, and social
              justice scores. By leveraging our data, developers can create
              applications that educate users about the impact of their
              consumption habits and guide them towards more sustainable
              alternatives.
            </p>

            <span className="spacer" id="fields"></span>
            <h2>Fields</h2>
            <p>
              We assign a growing number of sustainability information to a
              product. Below are the current available fields. You can read more{' '}
              <a href="https://consupedia.com/our-database/" target="_blank">
                here
              </a>
              .
            </p>

            <table>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Name (Sv)</th>
                  <th>Name (En)</th>
                  <th>Area</th>
                  <th>Example</th>
                  <th style={{ minwidth: '150px' }}>Type</th>
                  <th>Data type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>climateScore</td>
                  <td>Klimat</td>
                  <td>Climate score</td>
                  <td>Climate</td>
                  <td>38</td>
                  <td style={{ minwidth: '150px' }}>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>CO2e</td>
                  <td>Klimat CO2eq</td>
                  <td>Climate CO2eq</td>
                  <td>Climate</td>
                  <td>0.05</td>
                  <td>null, FLOAT (~ 0.01 - 200.00)</td>
                  <td>Float</td>
                </tr>
                <tr>
                  <td>novaScore</td>
                  <td>NOVA</td>
                  <td>NOVA</td>
                  <td>Health</td>
                  <td>4</td>
                  <td>
                    4-gradig skala, där 1 är minst processad och 4 är mest
                    processad
                  </td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>nutriScore</td>
                  <td>Nutri-Score</td>
                  <td>Nutri-Score</td>
                  <td>Health</td>
                  <td>E</td>
                  <td>5-gradig skala, bokstäver A till E där A är bäst</td>
                  <td>String</td>
                </tr>
                <tr>
                  <td>healthScore</td>
                  <td>Hälsa</td>
                  <td>Health score</td>
                  <td>Health</td>
                  <td>15</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>antibioticsResistanceScore</td>
                  <td>Antibiotikaresistens</td>
                  <td>Antibiotic resistance score</td>
                  <td>Health</td>
                  <td>98</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>overconsumptionRisk</td>
                  <td>Risk för överkonsumtion</td>
                  <td>Overconsumption risk</td>
                  <td>Health</td>
                  <td>Fenylhydrazin,kumarin</td>
                  <td>null, STRING (comma-separated values in string)</td>
                  <td>String</td>
                </tr>
                <tr>
                  <td>socialJusticeScore</td>
                  <td>Social rättvisa</td>
                  <td>Social justice score</td>
                  <td>Justice</td>
                  <td>75</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>equalityScore</td>
                  <td>Jämlikhet</td>
                  <td>Equality score</td>
                  <td>Justice</td>
                  <td>96</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>workerRightsScore</td>
                  <td>Arbetsförhållande</td>
                  <td>Worker rights score</td>
                  <td>Justice</td>
                  <td>95</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>animalProtectionScore</td>
                  <td>Djurvälfärd</td>
                  <td>Animal protection score</td>
                  <td>Justice</td>
                  <td>86</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>childLaborScore</td>
                  <td>Barnarbete</td>
                  <td>Child labor score</td>
                  <td>Justice</td>
                  <td>100</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>biodiversityScore</td>
                  <td>Biologisk mångfald</td>
                  <td>Biodiversity score</td>
                  <td>Climate</td>
                  <td>69</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>pesticidesUseScore</td>
                  <td>Bekämpningsmedel</td>
                  <td>Pesticides use score</td>
                  <td>Climate</td>
                  <td>null</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>fertilizersUseScore</td>
                  <td>Övergödning</td>
                  <td>Fertilizer use score</td>
                  <td>Climate</td>
                  <td>94</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>landUseScore</td>
                  <td>Landanvändning</td>
                  <td>Land use score</td>
                  <td>Climate</td>
                  <td>null</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>waterScore</td>
                  <td>Vattenfotavtryck</td>
                  <td>Water footprint score</td>
                  <td>Water</td>
                  <td>30</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>transportScore</td>
                  <td>Transport</td>
                  <td>Transport score</td>
                  <td>Climate</td>
                  <td>95</td>
                  <td>null-100</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>isEco</td>
                  <td>Ekologisk</td>
                  <td>Organic</td>
                  <td>Climate</td>
                  <td>1</td>
                  <td>null, 0-1</td>
                  <td>Integer</td>
                </tr>
                <tr>
                  <td>allergenList</td>
                  <td>Allergener</td>
                  <td>Allergens</td>
                  <td>Health</td>
                  <td>Sojabönor, Laktos, Mjölkprotein</td>
                  <td>null, STRING (comma separated values in string)</td>
                  <td>String</td>
                </tr>
                <tr>
                  <td>packageInformation</td>
                  <td>Förpackningsinformation</td>
                  <td>Package information</td>
                  <td>Climate</td>
                  <td>
                    Produkt med hög miljöpåverkan, viktigt att all produkt
                    förbrukas.
                  </td>
                  <td>null, STRING (short text with information)</td>
                  <td>String</td>
                </tr>
              </tbody>
            </table>

            <span className="spacer" id="restful"></span>
            <h2>RESTful API's</h2>
            <p>
              Consupedia offers two APIs to address the distinct needs of its
              users: the Connect API and the Product API. The Connect API
              enables subscription to specific products, while the Product API
              serves as a tool for product research. These APIs facilitate
              streamlined access to essential product information and enable
              businesses and developers to make informed decisions.
            </p>

            <p>
              Read the API reference{' '}
              <a href="/api-reference/#endpoints">here</a>.
            </p>

            <span className="spacer" id="webhook"></span>
            <h2>Webhook</h2>
            <p>
              Webhooks is a convenient way to get notified when a product is
              updated with new information. Webhooks allow you to build or set
              up integrations which subscribe to product update events. Rather
              than requiring you to pull information via our API, webhooks will
              push information to your endpoint. When there is new data about a
              subscribed product, we'll send a HTTP POST payload to the
              webhook's configured URL. Webhooks is a great way to keep your
              product information up to date.
            </p>

            <p>
              Read our webhook service <a href="/webhook">here</a>.
            </p>
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>You need any help with the Consupedia platform? Get in touch:</p>
            <p>
              <strong>
                <a href="mailto:tech-support@consupedia.com">
                  tech-support@consupedia.com
                </a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Documentation
